import type { GetServerSidePropsContext } from 'next';
import { Locale } from '@fxtr/i18n';
import config from 'config';

export const isBrowser = typeof window !== 'undefined';

type CookieDomain = string;
type I18nDomain = {
  domain: CookieDomain;
  defaultLocale?: Locale;
};
type I18nProductionDomain = {
  locale: Locale;
  domain: CookieDomain;
};

export const getDomainFromHostname = (
  hostname: string,
  domains: CookieDomain[] = config.get('public.cookie.domains')
): CookieDomain => {
  const findDomain = (domain: CookieDomain) => hostname.endsWith(domain);
  const match = domains.find(findDomain);
  if (!match) {
    throw new Error(
      `Cannot determine cookie domain name for hostname(${hostname}) from config(public.cookie.domains). Options include ${domains.join()}`
    );
  }
  return match;
};

const getHostNameFromWindow = (): string => {
  if (typeof window === 'undefined') {
    throw new Error('Cannot determine hostname without window');
  }
  return window.location && window.location.hostname;
};

/** @deprecated - Client side cookies are discouraged */
export const getClientSideCookieDomain = (getHostName = getHostNameFromWindow): string => {
  const domain = getDomainFromHostname(getHostName());
  return domain;
};

export const getServerSideCookieDomain = (req: GetServerSidePropsContext['req']): string => {
  if (isBrowser) {
    throw new Error('Cannot use this method on the client side');
  }
  if (!req.url) {
    throw new Error('Cannot determine cookie hostname');
  }
  const url = new URL(req.url, `http://${req.headers.host}`);
  const domain = getDomainFromHostname(url.hostname);
  return domain;
};

///
// Get locale
///
export const getLocaleFromHostname = (
  hostname: string,
  domains = config.get<I18nDomain[]>('public.i18n.domains'),
  defaultLocale = config.get<Locale>('public.i18n.defaultLocale')
): Locale => {
  const findDomain = (match: I18nDomain) => match.defaultLocale && hostname.includes(match.domain);
  const match = domains.find(findDomain);
  if (match && match.defaultLocale) {
    return match.defaultLocale;
  }
  return defaultLocale;
};

export const getDomainForLocale = (locale: Locale | unknown): string => {
  const domains = config.get<I18nDomain[]>('public.i18n.domains');
  const domainLocale = locale || config.get<Locale>('public.i18n.defaultLocale') || Locale.EN_GB;
  const defaultDomain =
    domains.find(({ defaultLocale }) => defaultLocale === domainLocale) ||
    domains.find(({ defaultLocale }) => !defaultLocale) ||
    domains[0];
  return defaultDomain?.domain;
};

export const getLocale = (getHostName = getHostNameFromWindow): Locale => {
  const domain = getLocaleFromHostname(getHostName());
  return domain;
};

export const isLocale = (locales: Locale[]): boolean => {
  return locales.includes(getLocale());
};

/**
 * Dynamic, now depends on the locale
 */
export const getLandingUrl = () => {
  if (isBrowser) {
    return window.location.origin;
  }

  throw new Error('Cannot use this method on the server side');
};

export type ContextRequestHeaders = {
  headers: {
    host: string;
  };
};

export const getLandingUrlFromRequest = (req: ContextRequestHeaders): string => {
  if (!req.headers.host) {
    throw new Error('getLandingUrlFromRequest method needs a valid req.headers.host');
  }

  return req.headers.host;
};

export const getProductionDomainFromLocale = (
  locale: string,
  productionDomains: I18nProductionDomain[] = config.get('public.i18n.productionDomains')
): string => {
  const productionDomain = productionDomains.find(
    (match: I18nProductionDomain) => match.locale === locale
  ) as I18nProductionDomain;

  if (!productionDomain) {
    throw new Error(`There is not production domain configured for the ${locale} locale`);
  }

  return productionDomain.domain;
};
