/* eslint-disable max-classes-per-file */
import { isValidPostcode, Locale, Segment } from '@fxtr/i18n';
import { vrmUKRegex, vrmFRRegex } from '$constants/regexp';
import { getSegmentFromLocale } from '$util/getSegment';

export const sanitizeString = (str: string): string => String(str).replace(/\s+/g, '').trim();

type VrmValidationErrorCode = 'EMPTY' | 'INVALID';

export class VrmValidationError extends Error {
  static EMPTY = 'EMPTY' as const;

  static INVALID = 'INVALID' as const;

  code: VrmValidationErrorCode;

  constructor(code: VrmValidationErrorCode) {
    super();
    this.name = this.constructor.name;
    this.code = code;
  }
}

export const validateVrm = (vrm: string, locale: Locale): void => {
  if (!vrm.length) {
    throw new VrmValidationError(VrmValidationError.EMPTY);
  }

  switch (locale) {
    case Locale.FR_FR:
      if (!vrmFRRegex.test(vrm)) {
        throw new VrmValidationError(VrmValidationError.INVALID);
      }
      break;
    default:
      if (vrm.length > 8 || !vrmUKRegex.test(vrm)) {
        throw new VrmValidationError(VrmValidationError.INVALID);
      }
      break;
  }
};

type PostcodeValidationErrorCode = 'EMPTY' | 'INVALID';

export class PostcodeValidationError extends Error {
  static EMPTY = 'EMPTY' as const;

  static INVALID = 'INVALID' as const;

  code: PostcodeValidationErrorCode;

  constructor(code: PostcodeValidationErrorCode) {
    super();
    this.name = this.constructor.name;
    this.code = code;
  }
}

export const validatePostcode = (postcode: string, locale: Locale): void => {
  if (!postcode.length) {
    throw new PostcodeValidationError(PostcodeValidationError.EMPTY);
  }

  const segment = getSegmentFromLocale(locale);

  switch (segment) {
    case Segment.Fixter_FR:
      if (!isValidPostcode(postcode, segment)) {
        throw new PostcodeValidationError(PostcodeValidationError.INVALID);
      }
      break;
    default:
      if (postcode.length > 8 || !isValidPostcode(postcode, segment)) {
        throw new PostcodeValidationError(PostcodeValidationError.INVALID);
      }
      break;
  }
};
